.App {
  text-align: center;
}

table {
  border-collapse: collapse;
  width: 90%;
  margin: auto;
}

th {
  font-weight: bold;
}

td,
th {
  border: 1px solid #ccc;
  text-align: center;
  vertical-align: middle;
  padding: 16px;
}
